
import { Component, Prop } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { IReceivedOrder } from "@/types/received_order";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  @Prop({ default: () => [] })
  public propReceivedOrderList!: IReceivedOrder[];

  //
  // variables
  //
  public headers = [
    { text: "受注管理番号", value: "management_no", sortable: false },
    { text: "顧客名 / 支店名", value: "short_name", sortable: false },
    { text: "顧客注文番号", value: "order_no", sortable: false },
    { text: "受注状況", value: "order_status", sortable: false },
    { text: "希望納品日", value: "desired_delivery_date", sortable: false },
    { text: "回答納品日", value: "fixed_delivery_date", sortable: false },
    { text: "納品先", value: "destination", sortable: false },
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "受注数量", value: "quantity", sortable: false, align: "end" },
    { text: "ロット引当", value: "shipment", sortable: false }
  ];

  //
  // methods
  //
  // v-data-tableのitems用にリストデータを整形
  get setValue() {
    return this.propReceivedOrderList.map(received_order => ({
      id: received_order.id,
      order_no: received_order.order_no,
      management_no: received_order.management_no,
      short_name: received_order.short_name,
      branch_short_name: received_order.branch_short_name || "ー",
      order_status: received_order.order_status,
      is_asap: received_order.is_asap,
      desired_delivery_date: received_order.desired_delivery_date,
      fixed_delivery_date: received_order.fixed_delivery_date,
      destination: received_order.destination,
      received_orders_management_id:
        received_order.received_orders_management_id,
      products: received_order.product_name_array,
      is_remaining_shipment: received_order.is_remaining_shipment
    }));
  }

  //行をクリックした際の挙動
  public rowClick(received_order: IReceivedOrder) {
    this.$router.push(`/admin/received_order/${received_order.id}`);
  }

  //ロット引当ボタンをクリックした際の挙動
  public shipment_selected(item: IReceivedOrder) {
    this.$router.push(`/admin/received_order/${item.id}/shipment/create`);
  }
}
