
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import ReceivedOrderList from "@/components/admin/list/ReceivedOrderList.vue";
import { IReceivedOrder } from "@/types/received_order";
import { IPagination, ISelectItem } from "@/types";
import { ICustomerListRequest } from "@/types/customer";
import {
  IReceivedOrdersManagement,
  IReceivedOrdersManagementListRequest
} from "@/types/received_orders_management";
import { ICustomerProductListRequest } from "@/types/customer_product";
import { IProductListRequest } from "@/types/product";

@Component({ components: { ReceivedOrderList } })
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // received_orders_management
  @Action("received_orders_management/adminGetList")
  public getList!: (request: IReceivedOrdersManagementListRequest) => boolean;

  @Getter("received_orders_management/list")
  public receivedOrdersManagementList!: IReceivedOrdersManagement[];

  @Getter("received_orders_management/pagination")
  public pagination!: IPagination<IReceivedOrder[]>;

  @Mutation("received_order/clear")
  public clear!: () => void;

  // received_order
  @Action("received_order/adminGetList")
  public getReceivedOrderList!: (
    request: IReceivedOrdersManagementListRequest
  ) => boolean;

  @Getter("received_order/list")
  public receivedOrderList!: IReceivedOrder[];

  @Getter("received_order/pagination")
  public receivedOrderPagination!: IPagination<IReceivedOrder[]>;

  @Mutation("received_order/clear")
  public clearReceivedOrder!: () => void;

  // customer
  @Action("customer/adminGetList")
  public getCustomerList!: (request: ICustomerListRequest) => boolean;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Mutation("customer/clear")
  public customerClear!: () => void;

  // customer_product
  @Action("customer_product/adminGetList")
  public getCustomerProductList!: (
    request: ICustomerProductListRequest
  ) => boolean;

  @Getter("customer_product/selectItem")
  public customerProductList!: ISelectItem[];

  @Mutation("customer_product/clear")
  public customerProductClear!: () => void;

  // product
  @Action("product/adminGetList")
  public getProductList!: (request: IProductListRequest) => boolean;

  @Getter("product/selectItem")
  public productList!: ISelectItem[];

  @Mutation("product/clear")
  public productClear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeSearchQuery() {
    this.queryToParams();
    if (this.params.is_relation_search) {
      await this.getList(this.params);
    }
    if (!this.params.is_relation_search) {
      await this.getReceivedOrderList(this.params);
    }
  }

  // パラメータ定義
  public params: IReceivedOrdersManagementListRequest = {
    page: 1,
    customer: 0,
    customer_pn: 0,
    product: 0,
    relation_search: "",
    search: "",
    search_span_start: "",
    search_span_end: "",
    sort_date_column: "",
    sort_direction: "",
    is_asap: false,
    is_null_fixed_delivery_date: false,
    is_relation_search: true,
    is_memo: false,
    multiple_order_status: []
  };

  //
  // variables
  //
  public received_order_status = Vue.prototype.$receivedOrderStatus;
  public menu = {
    start: false,
    end: false,
    day: false
  };
  public add_span_end = false;
  public span_end_show = true;
  public span_day_show = false;
  public rules = {};

  public headers = [
    { text: "顧客注文番号", value: "order_no", sortable: false },
    { text: "受注状況", value: "order_status", sortable: false },
    { text: "希望納品日", value: "desired_delivery_date", sortable: false },
    { text: "回答納品日", value: "fixed_delivery_date", sortable: false },
    { text: "納品先", value: "destination", sortable: false },
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "受注数量", value: "quantity", sortable: false, align: "end" },
    { text: "ロット引当", value: "shipment", sortable: false }
  ];

  public status_items = [
    { value: this.received_order_status.new_received_order, text: "新規受注" },
    { value: this.received_order_status.received_ordered, text: "受注済み" },
    {
      value: this.received_order_status.fixed_delivery_date,
      text: "納期回答済"
    },
    { value: this.received_order_status.estimated_shipment, text: "出荷予定" },
    { value: this.received_order_status.shipped, text: "出荷済み" },
    {
      value: this.received_order_status.cancel_received_order,
      text: "受注キャンセル"
    },
    { value: this.received_order_status.division_storing, text: "分納" }
  ];

  public sort_date_list = [
    { value: "", text: "全て表示" },
    { value: "order_date", text: "受注日" },
    { value: "desired_delivery_date", text: "希望納品日" },
    { value: "fixed_delivery_date", text: "回答納品日" }
  ];

  public sort_items = [
    { value: "", text: "選択なし" },
    { value: "asc", text: "昇順" },
    { value: "desc", text: "降順" }
  ];

  //
  // methods
  //
  private async created() {
    this.clear();
    this.customerClear();
    this.customerProductClear();
    this.productClear();
    this.clearReceivedOrder();
    this.queryToParams();

    await Promise.all([
      this.params.is_relation_search
        ? this.getList(this.params)
        : this.getReceivedOrderList(this.params),
      this.getCustomerList({ per_page: 0 }),
      this.getCustomerProductList({ per_page: 0 }),
      this.getProductList({ per_page: 0 })
    ]);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      page: query.page ? Number(query.page) : 1,
      customer: query.customer ? Number(query.customer) : 0,
      customer_pn: query.customer_pn ? Number(query.customer_pn) : 0,
      product: query.product ? Number(query.product) : 0,
      relation_search: query.relation_search
        ? decodeURI(query.relation_search as string)
        : "",
      search: query.search ? decodeURI(query.search as string) : "",
      sort_date_column: query.sort_date_column
        ? decodeURI(query.sort_date_column as string)
        : "",
      sort_direction: query.sort_direction
        ? decodeURI(query.sort_direction as string)
        : "",
      search_span_start: query.search_span_start
        ? decodeURI(query.search_span_start as string)
        : "",
      search_span_end: query.search_span_end
        ? decodeURI(query.search_span_end as string)
        : "",
      is_asap: query.is_asap ? Boolean(query.is_asap) : false,
      is_null_fixed_delivery_date: query.is_null_fixed_delivery_date
        ? Boolean(query.is_null_fixed_delivery_date)
        : false,
      is_relation_search:
        typeof query.is_relation_search !== "undefined"
          ? Boolean(query.is_relation_search)
          : true,
      is_memo: query.is_memo ? Boolean(query.is_memo) : false,
      multiple_order_status: this.$route.query.multiple_order_status
        ? Array.isArray(this.$route.query.multiple_order_status)
          ? this.$route.query.multiple_order_status.map(Number)
          : this.$route.query.multiple_order_status.split(",").map(Number)
        : []
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.params.page ? String(this.params.page) : "1",
          customer: this.params.customer ? String(this.params.customer) : "0",
          customer_pn: this.params.customer_pn
            ? String(this.params.customer_pn)
            : "0",
          product: this.params.product ? String(this.params.product) : "0",
          relation_search: this.params.relation_search,
          search: this.params.search,
          sort_date_column: this.params.sort_date_column,
          sort_direction: this.params.sort_direction,
          search_span_start: this.params.search_span_start,
          search_span_end: this.params.search_span_end,
          is_asap: this.params.is_asap ? String(this.params.is_asap) : "",
          is_null_fixed_delivery_date: this.params.is_null_fixed_delivery_date
            ? String(this.params.is_null_fixed_delivery_date)
            : "",
          is_relation_search: this.params.is_relation_search
            ? String(this.params.is_relation_search)
            : "",
          is_memo: this.params.is_memo ? String(this.params.is_memo) : "",
          multiple_order_status: this.params.multiple_order_status
            ? this.params.multiple_order_status.map(String)
            : []
        }
      })
      .catch(() => {});
  }

  // v-data-tableのitems用にリストデータを整形
  get setValue() {
    return this.receivedOrdersManagementList
      .map(management =>
        management.received_orders.map(received_order => received_order)
      )
      .reduce(function (item, val) {
        return item.concat(val);
      }, [])
      .map(item => ({
        id: item.id,
        order_no: item.order_no,
        management_no: item.management_no,
        short_name: item.short_name,
        branch_short_name: item.branch_short_name,
        order_status: item.order_status,
        is_asap: item.is_asap,
        desired_delivery_date: item.desired_delivery_date,
        fixed_delivery_date: item.fixed_delivery_date,
        destination: item.destination,
        received_orders_management_id: item.received_orders_management_id,
        products: item.product_name_array,
        is_remaining_shipment: item.is_remaining_shipment
      }));
  }

  // v-data-tableのグループヘッダーの受注管理番号、顧客名をセットする
  public setManagementNo(items: IReceivedOrder[]) {
    return items[0].management_no;
  }

  public setCustomerName(items: IReceivedOrder[]) {
    return items[0].short_name;
  }

  public setBranchName(items: IReceivedOrder[]) {
    return items[0].branch_short_name || "ー";
  }

  // 開始日、終了日、日付指定のリセット
  public clearSearchStart() {
    this.params.search_span_start = "";
    this.searchFirst();
  }

  public clearSearchEnd() {
    this.params.search_span_end = "";
    this.searchFirst();
  }

  public clearSearchSpanDay() {
    this.params.search_span_start = "";
    this.params.search_span_end = "";
    this.searchFirst();
  }

  // 日付指定チェックボックスをクリックした際の挙動
  public span_toggle() {
    if (this.add_span_end == false) {
      this.params.search_span_end = "";
      this.params.search_span_start = "";
      this.span_end_show = true;
      this.span_day_show = false;
      this.searchFirst();
    } else {
      this.params.search_span_end = "";
      this.params.search_span_start = "";
      this.span_end_show = false;
      this.span_day_show = true;
      this.params.search_span_end = this.params.search_span_start;
      this.searchFirst();
    }
  }

  // 日付指定した際の挙動
  public async searchFirstSpanDay() {
    this.params.page = 1;
    this.params.search_span_end = this.params.search_span_start;
    await this.paramsToQuery();
  }

  // 期間選択時のバリデーション
  public spanEndRule() {
    if (
      !this.params.search_span_end ||
      !this.params.search_span_start ||
      this.params.search_span_end >= this.params.search_span_start
    ) {
      return true;
    }

    return "正しい日付を入力してください";
  }

  //行をクリックした際の挙動
  public rowClick(received_order: IReceivedOrder) {
    this.$router.push(`/admin/received_order/${received_order.id}`);
  }

  //ロット引当ボタンをクリックした際の挙動
  public shipment_selected(item: IReceivedOrder) {
    this.$router.push(`/admin/received_order/${item.id}/shipment/create`);
  }
}
